import React, {memo} from 'react';

import SideImageBlock from '@/components/molecules/SideImageBlock';

import {IToolsetContent, toolsetContent} from './content';
import styles from './styles.module.scss';

function ToolsetSection() {
  return (
    <section className={styles.root}>
      <h2 className={styles.subtitle}>Our toolset for an integrated and smooth execution process 💪</h2>

      <div className={styles.toolsetItemsContainer}>
        {toolsetContent.map((toolsetItem: IToolsetContent, index: number) => (
          <div key={index} className={styles.toolsetItem}>
            <SideImageBlock {...toolsetItem} side={index % 2 === 0 ? 'left' : 'right'} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default memo(ToolsetSection);
