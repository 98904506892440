import React, {memo} from 'react';

import styles from './styles.module.scss';

function OurVisionSection() {
  return (
    <section className={styles.root}>
      <p className={styles.beforeTitle}>WHO WE ARE</p>
      <h1 className={styles.title}>
        We are a multidisciplinary team. Artists, tech magicians, OG designers and digital visionaries that got together
        to help forward-thinking companies start meaningful projects that will disrupt the current industry.
      </h1>
    </section>
  );
}

export default memo(OurVisionSection);
