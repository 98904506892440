import React, {memo} from 'react';

import Button from '@/components/atoms/Button';
import CalendlyPopup from '@/mechanisms/CalendlyPopup';

import styles from './styles.module.scss';

function HeroMolecule() {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <p className={styles.beforeTitle}>WELCOME TO GRG</p>
        <h1 className={styles.title}>Multidisciplinary Product Design team working with world changing projects.</h1>
        <Button onClick={CalendlyPopup.open}>Book a call</Button>
      </div>
    </div>
  );
}

export default memo(HeroMolecule);
