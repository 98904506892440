import {Settings} from 'react-slick';

export const settings: Settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  pauseOnHover: true,
  swipeToSlide: true,
  speed: 500,
  autoplaySpeed: 3500,
  slidesToShow: 1,
  centerMode: true,
  arrows: false,
  variableWidth: true,
  initialSlide: 1,
};
