import {memo} from 'react';

import featuresList from './features-list';
import styles from './styles.module.scss';

function FeaturesBar() {
  return (
    <ul className={styles.list}>
      {featuresList.map((feature: string, index: number) => (
        <li key={index}>{`/ ${feature}`}</li>
      ))}
    </ul>
  );
}

export default memo(FeaturesBar);
