import React, {memo} from 'react';

import ResponsiveBox from '@/components/atoms/ResponsiveBox';
import AnimatedBackground from '@/components/molecules/AnimatedBackground';
import FeaturesBar from '@/components/molecules/FeaturesBar';
import HeroMolecule from '@/components/molecules/HeroMolecule';
import MarqueFeaturesBar from '@/components/molecules/MarqueFeaturesBar';
import TopBar from '@/components/molecules/TopBar';

import styles from './styles.module.scss';

function HeroSection() {
  return (
    <section className={styles.root}>
      <AnimatedBackground />

      <div className={styles.content}>
        <TopBar />
        <HeroMolecule />

        <ResponsiveBox xs={false} lg={true}>
          <FeaturesBar />
        </ResponsiveBox>
        <ResponsiveBox xs={true} lg={false}>
          <MarqueFeaturesBar />
        </ResponsiveBox>
      </div>
    </section>
  );
}

export default memo(HeroSection);
