import {memo} from 'react';

import styles from './styles.module.scss';

function TopBar() {
  return (
    <>
      <header className={styles.root}>
        <div className={styles.content}>
          <img src={'/assets/svg/logo.svg'} alt={'Logo'} className={styles.logo} />
        </div>
      </header>
    </>
  );
}

export default memo(TopBar);
