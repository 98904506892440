import React, {memo} from 'react';

import withLayout from '@/ad-hocs/withLayout';
import Main from '@/components/layouts/Main';
import BrandsCarousel from '@/components/molecules/BrandsCarousel';
import ClientsExperienceSection from '@/components/organisms/ClientsExperienceSection';
import Footer from '@/components/organisms/Footer';
import HeroSection from '@/components/organisms/HeroSection';
import OurVisionSection from '@/components/organisms/OurVisionSection';
import SummarySection from '@/components/organisms/SummarySection';
import ToolsetSection from '@/components/organisms/ToolsetSection';
import TrustedBrandsSection from '@/components/organisms/TrustedBrandsSection';

function Intro() {
  return (
    <>
      <HeroSection />
      <OurVisionSection />
      <ToolsetSection />
      <TrustedBrandsSection />
      <BrandsCarousel />
      <ClientsExperienceSection />
      <SummarySection />
      <Footer />
    </>
  );
}

export default withLayout(memo(Intro), Main);
