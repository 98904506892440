import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, {memo} from 'react';
import Marquee from 'react-marquee-slider';

import featuresList from '../FeaturesBar/features-list';
import styles from './styles.module.scss';

const dummyFn = () => {};

function MarqueFeaturesBar() {
  return (
    <div className={styles.root}>
      <Marquee
        velocity={5}
        direction={'ltr'}
        scatterRandomly={false}
        onInit={dummyFn}
        onFinish={dummyFn}
        resetAfterTries={100}
      >
        {featuresList.map((feature: string, index: number) => (
          <span key={index} className={styles.slide}>{`/ ${feature}`}</span>
        ))}
      </Marquee>
    </div>
  );
}

export default memo(MarqueFeaturesBar);
