import {defaultConfig, IAnimationConfig} from '@usertive/react-fluid-animation';

export interface IAutomationConfig {
  startingSplats: number;
  interval: {
    splats: number;
    timing: number;
  };
}

export const automationConfig: IAutomationConfig = {
  startingSplats: 6,
  interval: {
    splats: 1,
    timing: 10000,
  },
};

export const boostedAnimationConfig: Partial<IAnimationConfig> = {
  ...defaultConfig,
  splatRadius: 0.04,
  colorsPool: [
    '#FF1100',
    '#FF0046',
    '#5D00FF',
    '#0043FF',
    '#0088FF',
    '#00DCFF',
    '#00FFF7',
    '#00FFD4',
    '#00FFA2',
    '#DADADA',
    '#FFFFFF',
  ],
};

export const standardAnimationConfig: Partial<IAnimationConfig> = {
  ...defaultConfig,
  colorsPool: [
    '#FF1100',
    '#FF0046',
    '#5D00FF',
    '#0043FF',
    '#0088FF',
    '#00DCFF',
    '#00FFF7',
    '#00FFD4',
    '#00FFA2',
    '#DADADA',
    '#FFFFFF',
  ],
};
