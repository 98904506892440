import {getDisplayName} from 'next/dist/next-server/lib/utils';
import React, {ComponentType, forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes} from 'react';

import {ILayoutAssignableElement, ILayoutComponent} from '@/models/ILayout';

export default function withLayout<P extends object>(
  WrappedComponent: ComponentType<P>,
  LayoutComponent: ILayoutComponent
) {
  type Ref = ComponentType<P>;

  const forwardRefFunc: ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<Ref>> &
    ILayoutAssignableElement = forwardRef<Ref, P>((props, ref) => {
    return <WrappedComponent {...(props as P)} ref={ref} />;
  });

  forwardRefFunc.displayName = `withLayout(${getDisplayName(WrappedComponent)})`;
  forwardRefFunc.layout = LayoutComponent;

  return forwardRefFunc;
}
