export interface IToolsetContent {
  image: string;
  title: string;
  description: string;
  list: string[];
}

export const toolsetContent: IToolsetContent[] = [
  {
    image: '/assets/svg/strategy-image.svg',
    title: 'Strategy',
    description:
      'A solid strategy is the key to any successful project. It is no different when it comes to digital products. Our solutions are creative, innovative and on point. We collaborated with companies around the globe and across sectors to create disruptive strategies.',
    list: ['Product Strategy', 'Brand Strategy', 'Value Creation', 'Design OPS'],
  },
  {
    image: '/assets/svg/visual-image.svg',
    title: 'Visual',
    description:
      "The speed with which digital experiences are evolving is unreal. The visual virtual space is dynamic and demanding. We don't just have one eye on it, we see beyond. Our design skills include branding, creative direction, UI/UX design, illustration, and animation.",
    list: ['Product Design', 'Interactive Design', 'Design Systems', 'Motion Branding'],
  },
  {
    image: '/assets/svg/development-image.svg',
    title: 'Development',
    description:
      'Any product needs development. A team offering digital products from design to construction means less hassle for you and more integration and quality for the product. Our developers work closely with our designers ensuring a spotless implementation of our state-of-the-art designs.',
    list: ['Web Development', 'App Development', 'Software Development', 'Game Development'],
  },
];
