export interface IClientFeedback {
  text: string;
  author: {
    imageSrc: string;
    fullName: string;
    profession: string;
    company?: string;
  };
}

export const clientsFeedbackList: readonly IClientFeedback[] = [
  {
    text:
      'We have worked with GRG on multiple projects. Their trademark is providing well-thought, high-quality work. They are also creative and is great to work with.',
    author: {
      imageSrc: '/assets/jpg/client-image-1.jpg',
      fullName: 'Edmond Awad',
      profession: 'Postdoctoral Associate',
      company: 'MIT Media Lab.',
    },
  },
  {
    text:
      'I am extremely pleased with the work they performed and their willingness to understand and meet our needs and goals. I recommend them to all of my colleagues, and I will certainly use them in my projects in the future.',
    author: {
      imageSrc: '/assets/jpg/client-image-2.jpg',
      fullName: 'Morgan R. Frank',
      profession: 'Research Scientist',
      company: 'MIT Media Lab',
    },
  },
  {
    text:
      'The team is fantastic. They’re prompt, flexible, reasonable, and have a great vision. I’m looking forward to working with them in the future.',
    author: {
      imageSrc: '/assets/jpg/client-image-3.jpg',
      fullName: 'Nick Obradovich',
      profession: 'Research Scientist',
      company: 'MIT Media Lab',
    },
  },
] as const;
