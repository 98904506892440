import {Animation} from '@usertive/react-fluid-animation';
import {MutableRefObject, useCallback, useRef} from 'react';

import {IAutomationConfig} from './config';

export type IntervalControls = [startInterval: () => void, stopInterval: () => void];

export default function useIntervalControls(
  animationRef: MutableRefObject<Animation | null>,
  animationConfig: IAutomationConfig
): IntervalControls {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startIterval = useCallback(() => {
    intervalRef.current = setInterval(() => {
      animationRef.current?.addRandomSplats(animationConfig.interval.splats);
    }, animationConfig.interval.timing);
  }, [animationConfig, animationRef, intervalRef]);

  const stopInterval = useCallback(() => {
    intervalRef.current && clearInterval(intervalRef.current);
  }, [intervalRef]);

  return [startIterval, stopInterval];
}
