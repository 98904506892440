import clsx from 'clsx';
import {memo, ReactNode} from 'react';

import Breakpoint from '@/models/Breakpoint';

import styles from './styles.module.scss';

export interface IResponsiveBoxProps extends Partial<Record<Breakpoint, boolean>> {
  className?: string;
  children: ReactNode;
}

type ResponsiveClasses = Record<string, boolean>;

function ResponsiveBox(props: IResponsiveBoxProps) {
  const {className, children, xs, sm, md, lg, xl} = props;

  const responsiveClasses = (Object.keys({
    xs,
    sm,
    md,
    lg,
    xl,
  }) as Breakpoint[]).reduce((classes: ResponsiveClasses, breakpointName: Breakpoint) => {
    classes[`visible-${breakpointName}`] = props[breakpointName] === true;
    classes[`hidden-${breakpointName}`] = props[breakpointName] === false;
    return classes;
  }, {} as ResponsiveClasses);

  return <div className={clsx(styles.root, className, responsiveClasses)}>{children}</div>;
}

export default memo(ResponsiveBox);
