import {useEffect, useState} from 'react';

export default function useIsHydrated(): boolean {
  const [isAfterHydration, setIsAfterHydration] = useState<boolean>(false);

  // On component hydration complete
  useEffect(() => {
    if (!isAfterHydration) setIsAfterHydration(true);
  }, [isAfterHydration, setIsAfterHydration]);

  return isAfterHydration;
}
