import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import clsx from 'clsx';
import React, {memo} from 'react';
import Slider from 'react-slick';

import ClientFeedbackBlock from '@/components/molecules/ClientFeedbackBlock';

import {clientsFeedbackList, IClientFeedback} from './content';
import {settings as sliderSettings} from './slider';
import styles from './styles.module.scss';

function ClientsExperienceSection() {
  return (
    <section className={styles.root}>
      <h2 className={styles.subtitle}>Our clients’ experience 💙</h2>

      <div className={styles.slider}>
        <div className={clsx(styles.gradient, styles.leftGradient)} />

        <Slider {...sliderSettings} dotsClass={`slick-dots ${styles.sliderDots}`}>
          {clientsFeedbackList.map((clientFeedbackData: IClientFeedback, index: number) => (
            <ClientFeedbackBlock key={index} {...clientFeedbackData} />
          ))}
        </Slider>

        <div className={clsx(styles.gradient, styles.rightGradient)} />
      </div>
    </section>
  );
}

export default memo(ClientsExperienceSection);
