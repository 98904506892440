export interface ITrustedBrand {
  name: string;
  image: string;
}

export const brandsImages: ITrustedBrand[] = [
  {name: 'Massachusetts Institute of Technology', image: '/assets/png/brand-1.png'},
  {name: 'Max Planck Institute for Human Development', image: '/assets/png/brand-2.png'},
  {name: 'Blackboard', image: '/assets/png/brand-3.png'},
  {name: 'NYU', image: '/assets/png/brand-4.png'},
  {name: 'Google', image: '/assets/png/brand-5.png'},
];
