import clsx from 'clsx';
import {ButtonHTMLAttributes, DetailedHTMLProps, memo} from 'react';

import animationStyles from './animations.module.scss';
import styles from './styles.module.scss';

export interface IButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  href?: string;
}

function Button(props: IButtonProps) {
  const {children, className, href, ...ButtonProps} = props;

  let element = (
    <button className={clsx(styles.root, animationStyles.sweepToRight)} {...ButtonProps}>
      {children}
    </button>
  );

  if (href !== undefined)
    element = (
      <a href={href} className={styles.linkWrapper}>
        {element}
      </a>
    );

  return <div className={className}>{element}</div>;
}

export default memo(Button);
