import {Animation, IAnimationConfig} from '@usertive/react-fluid-animation';
import dynamic from 'next/dynamic';
import {memo, useCallback, useEffect, useRef, useState} from 'react';

import useIsHydrated from '@/hooks/useIsHydrated';

import {automationConfig, boostedAnimationConfig, standardAnimationConfig} from './config';
import useIntervalControls from './useIntervalControls';

const DynamicAnimation = dynamic(() => import('@usertive/react-fluid-animation'));

function AnimatedBackground() {
  const isHydrated = useIsHydrated();
  const animationRef = useRef<Animation | null>(null);
  const [componentReady, setComponentReady] = useState<boolean>(false);
  const [startInterval, stopInterval] = useIntervalControls(animationRef, automationConfig);
  const [animationConfig, setAnimationConfig] = useState<Partial<IAnimationConfig>>(boostedAnimationConfig);

  const handleCaptureAnimationRef = useCallback((animation: Animation) => {
    animationRef.current = animation;
    setComponentReady(true);
  }, []);

  const handleVisiblityChange = useCallback(() => {
    if (document.visibilityState === 'visible') startInterval();
    else stopInterval();
  }, [startInterval, stopInterval]);

  // On component ready
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (componentReady && animationRef.current) {
      animationRef.current.addRandomSplats(automationConfig.startingSplats);
      startInterval();
      timeoutId = setTimeout(() => {
        setAnimationConfig(standardAnimationConfig);
        timeoutId = null;
      }, 100);
    }

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [componentReady, animationRef, startInterval]);

  // On component unmount
  useEffect(() => {
    return () => stopInterval();
  }, [stopInterval]);

  // On window visibility change
  useEffect(() => {
    if (typeof document !== 'undefined') document.addEventListener('visibilitychange', handleVisiblityChange);
  }, [handleVisiblityChange]);

  return isHydrated ? <DynamicAnimation animationRef={handleCaptureAnimationRef} config={animationConfig} /> : null;
}

export default memo(AnimatedBackground);
