import React, {memo} from 'react';
import Slider from 'react-slick';

import {settings as sliderSettings} from '@/components/organisms/ClientsExperienceSection/slider';

import {brandsCarouselContent} from './content';
import styles from './styles.module.scss';

function BrandsCarousel() {
  return (
    <section className={styles.root}>
      <Slider {...sliderSettings} autoplaySpeed={4000} dotsClass={`slick-dots ${styles.sliderDots}`}>
        {brandsCarouselContent.map((brandImageSrc: string, index: number) => (
          <div key={index} className={styles.listElement}>
            <img className={styles.imageBox} src={brandImageSrc} alt={`Brand ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </section>
  );
}

export default memo(BrandsCarousel);
