import {memo} from 'react';

import {ILayoutProps} from '@/models/ILayout';

import styles from './styles.module.scss';

export interface IMainProps extends ILayoutProps {}

function Main(props: IMainProps) {
  const {children} = props;

  return <main className={styles.root}>{children}</main>;
}

export default memo(Main);
