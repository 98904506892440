const featuresList: string[] = [
  'Product Design',
  'Motion Branding',
  'Interactive Experiences',
  'Lighting Starts',
  'Design Systems',
  'MVP',
];

export default featuresList;
