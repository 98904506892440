import clsx from 'clsx';
import {memo} from 'react';

import {IToolsetContent} from '@/components/organisms/ToolsetSection/content';

import styles from './styles.module.scss';

export interface ISideImageBlockProps extends IToolsetContent {
  side: 'left' | 'right';
}

function SideImageBlock(props: ISideImageBlockProps) {
  const {image, title, description, list, side} = props;

  return (
    <figure className={clsx(styles.root, side === 'right' && styles.rightSide)}>
      <div className={styles.image}>
        <img src={image} alt={title} />
      </div>
      <figcaption className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        <ul className={styles.list}>
          {list.map((listElement: string, index: number) => (
            <li key={index} className={styles.listItem}>
              {listElement}
            </li>
          ))}
        </ul>
      </figcaption>
    </figure>
  );
}

export default memo(SideImageBlock);
