import {closePopupWidget, openPopupWidget} from 'react-calendly';
import {PageSettings} from 'react-calendly/typings/calendly';
import {PopupWidgetOptions} from 'react-calendly/typings/components/PopupText/PopupText';

class CalendlyPopup {
  private readonly popupOptions: PopupWidgetOptions & {pageSettings?: PageSettings} = {
    url: `${process.env.NEXT_PUBLIC_CALENDLY_URL}`,
    pageSettings: {
      backgroundColor: '08090c',
      primaryColor: 'ffffff',
      textColor: 'd2d3d7',
      hideEventTypeDetails: true,
      hideLandingPageDetails: true,
    },
  };

  public constructor() {
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  public open() {
    openPopupWidget(this.popupOptions);
  }

  public close() {
    closePopupWidget();
  }
}

export default new CalendlyPopup();
