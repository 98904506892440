import React, {memo} from 'react';

import Button from '@/components/atoms/Button';
import CalendlyPopup from '@/mechanisms/CalendlyPopup';

import styles from './styles.module.scss';

function SummarySection() {
  return (
    <section className={styles.root}>
      <h2 className={styles.title}>
        We like purpose and chemistry. We do not care about your VC. We care about what you want to achieve with your
        project. And we need to click with you. It’ll make collaborating much more fun - for you and us. So let’s
        arrange a call and find out!
      </h2>
      <Button className={styles.button} onClick={CalendlyPopup.open}>
        Pick a time
      </Button>
    </section>
  );
}

export default memo(SummarySection);
