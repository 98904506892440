import {memo} from 'react';

import {IClientFeedback} from '@/components/organisms/ClientsExperienceSection/content';

import styles from './styles.module.scss';

export type IClientFeedbackBlockProps = IClientFeedback;

function ClientFeedbackBlock(props: IClientFeedbackBlockProps) {
  const {text, author} = props;

  return (
    <figure className={styles.root}>
      <p className={styles.text}>{`"${text}"`}</p>
      <div className={styles.client}>
        <img src={author.imageSrc} alt={'feedback author'} className={styles.avatar} />

        <div className={styles.info}>
          <p className={styles.fullName}>{author.fullName}</p>
          <span className={styles.caption}>
            <p>{author.profession}</p>
            <p>{author.company}</p>
          </span>
        </div>
      </div>
    </figure>
  );
}

export default memo(ClientFeedbackBlock);
