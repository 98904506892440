import clsx from 'clsx';
import {memo} from 'react';

import ResponsiveBox from '@/components/atoms/ResponsiveBox';

import styles from './styles.module.scss';

function Footer() {
  return (
    <footer className={styles.root}>
      <div className={styles.box}>
        <h6 className={styles.title}>GRG Studios OÜ</h6>
        <p className={styles.caption}>Pärnu mnt 22</p>
        <p className={styles.caption}>10141 Tallinn</p>
        <p className={styles.caption}>Harju maakond</p>
        <p className={styles.caption}>ESTONIA</p>
      </div>

      <div className={styles.box}>
        <p className={styles.caption}>Business ID: 14428374</p>
        <p className={styles.caption}>VAT ID: EE102049367</p>
        <h6 className={styles.title} style={{marginTop: 16}}>
          hello@grgstudios.com
        </h6>
      </div>

      <div className={styles.box}>
        <ResponsiveBox xs={false} lg={true}>
          <img src={'/assets/svg/logo.svg'} alt={'Logo'} className={styles.logo} />
        </ResponsiveBox>

        <span className={styles.inlineTextsContainer}>
          <p className={styles.caption}>Copyright © {new Date().getFullYear().toString() + ' '}</p>
          <p className={clsx(styles.caption, styles.distinct)}>grgstudios.com</p>
        </span>
        <p className={styles.caption}>All rights reserved.</p>
      </div>
    </footer>
  );
}

export default memo(Footer);
