import React, {memo} from 'react';

import {brandsImages, ITrustedBrand} from './content';
import styles from './styles.module.scss';

function TrustedBrandsSection() {
  return (
    <section className={styles.root}>
      <h2 className={styles.subtitle}>Brands we collaborated with 🙌</h2>

      <ul className={styles.brandsList}>
        {brandsImages.map((trustedBrand: ITrustedBrand, index: number) => (
          <li key={index} className={styles.brandListElement}>
            <img src={trustedBrand.image} alt={`${trustedBrand.name} brand logo`} className={styles.image} />
          </li>
        ))}
      </ul>
    </section>
  );
}

export default memo(TrustedBrandsSection);
